import { useEffect, useState } from "react";
import HeaderComponent from "../../../components/Header/HeaderComponent";
import InputComponent from "../../../components/Input/InputComponent";
import { SimpleSearchParams } from "../../../hooks/SearchParams";
import { DeleteApi, PostApi } from "../../../services/Api";
import { PhoneNumber } from "../../../services/Mask";
import { UseFetch } from "../../../hooks/useFetch";
import Pagination from "../../../components/Pagination/PaginationComponent";
const ManageSuppliers = () => {
  let SearchParams = SimpleSearchParams();
  let [currentPage, setCurrentPage] = useState(1);
  let [loading, setLoading] = useState(false);
  let [product, setProduct] = useState({
    name: "",
    phoneNumber: "",
  });
  let page = SearchParams.get(`page`);
  const {
    data: suppliers,
    loadingSuppliers,
    update: updateSuppliers,
  } = UseFetch("suppliers", {
    params: { page, perPage: 10 },
  });
  let [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    if (loadingSuppliers || page === currentPage) return;
    setCurrentPage(page);
    updateSuppliers();
  }, [currentPage, loadingSuppliers, page, updateSuppliers]);

  const handleChange = (element) => {
    let target = element.currentTarget;
    setProduct({ ...product, [target.id]: target.value });
  };
  const handleSubmit = async () => {
    setErrorMessage('')
    if (!product.phoneNumber || !product.name)
      return setErrorMessage("Todos os campos são obrigatórios");
    console.log(product.phoneNumber.length);
    
    if (product.phoneNumber.length < 10)
      return setErrorMessage("Telefone invalido");
    console.log("phoneNumber", product.ph);

    let newNumber = product.phoneNumber.replace(/([^0-9])/g, "");
    newNumber = `+55${newNumber}`;

    setLoading(true);
    let response = await PostApi("/suppliers", {
      phoneNumber: newNumber,
      name: product.name,
    })
      .then((res) => res)
      .catch((err) => {
        setErrorMessage("Ocorreu um erro na criação do fornecedor");
        console.error(err);
        return err;
      });
    setLoading(false);
    if (response.status === 201) {
      setErrorMessage(null);
      updateSuppliers();
      setProduct({});
      document.querySelector("input#name").value = ""
      document.querySelector("input#phoneNumber").value = ""
    } else {
      setErrorMessage("Ocorreu um erro na criação do fornecedor");
    }
  };
  const handleDelete = async (uid) => {
    setLoading(true);
    let response = await DeleteApi(`/suppliers/${uid}`)
      .then((res) => res)
      .catch((err) => {
        setErrorMessage("Ocorreu um erro na criação do fornecedor");
        console.error(err);
        return err;
      });
    setLoading(false);
    if (response.status === 204) {
      setErrorMessage(null);
      updateSuppliers();
    } else {
      setErrorMessage("Ocorreu um erro na criação do fornecedor");
    }
  };

  return (
    <main id="createProduct">
      <HeaderComponent title="Fornecedores" lead="" />
      {errorMessage && <h2>{errorMessage}</h2>}
      <div
        style={{
          display: "flex",
        }}
      >
        <div style={{ padding: "0 8px 0 0", width: "100%" }}>
          <InputComponent
            id="name"
            label="Nome:"
            type="text"
            onChange={handleChange}
            required
            error={!product.name}
          />
        </div>
        <div style={{ padding: "0 8px", width: "100%" }}>
          <InputComponent
            value={
              product["phoneNumber"] && PhoneNumber(product["phoneNumber"])
            }
            onChange={handleChange}
            id="phoneNumber"
            minlength="10"
            maxlength="16"
            label="Telefone:"
            type="tel"
            required
          />
        </div>
        <div
          style={{
            alignSelf: "end",
            paddingBottom: "10px",
            padding: "0 0 8px 0",
          }}
        >
          <button
            disabled={!product.name || !product.phoneNumber}
            onClick={handleSubmit}
          >
            Adicionar
          </button>
        </div>
      </div>
      <main id="container" className={`view-list`}>
        <div
          style={{
            background: "#EDEDEF",
            color: "#1a1a1a",
            marginTop: "8px",
            padding: "16px 24px",
            display: "grid",
            gridTemplateColumns: "auto 33% 33%",
            alignItems: "center",
            boxShadow: " 2px 2px 2px #cecece50",
            borderRadius: "8px",
            cursor: "default",
          }}
        >
          <div style={{ textAlign: "center" }}>Fornecedor</div>
          <div style={{ textAlign: "center" }}>Valor</div>
          <div style={{ textAlign: "center" }}>Ações</div>
        </div>
        {suppliers?.suppliers && suppliers.suppliers.length ? (
          suppliers.suppliers.map((supplier, index) => (
            <>
              <div
                style={{
                  background: "#faf9f9",
                  padding: "16px",
                  display: "grid",
                  gridTemplateColumns: "auto 33% 33%",
                  alignItems: "center",
                  boxShadow: " 2px 2px 2px #cecece50",
                  borderRadius: "8px",
                }}
              >
                <div style={{ textAlign: "center" }}>{supplier.name}</div>
                <div style={{ textAlign: "center" }}>
                  {supplier?.phoneNumber &&
                    supplier.phoneNumber.length &&
                    PhoneNumber(supplier.phoneNumber)}
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button
                    style={{ background: "#d33c3c" }}
                    onClick={() => {
                      handleDelete(supplier.supplierId);
                    }}
                  >
                    Excluir
                  </button>
                </div>
              </div>
            </>
          ))
        ) : (
          <>
            <div
              style={{
                background: "#faf9f9",
                padding: "16px",
                display: "grid",
                gridTemplateColumns: "auto ",
                alignItems: "center",
                boxShadow: " 2px 2px 2px #cecece50",
                borderRadius: "8px",
              }}
            >
              <div style={{ textAlign: "center" }}>
                Não há nenhum fornecedor cadastrado
              </div>
            </div>
          </>
        )}
        <Pagination
          totalItems={suppliers?.totalItems || 1}
          totalPages={suppliers?.totalPages || 1}
        />
      </main>
    </main>
  );
};

export default ManageSuppliers;
