import React, { ChangeEvent, useEffect, useState } from "react";
import HeaderComponent from "../../../components/Header/HeaderComponent";
import InputComponent from "../../../components/Input/InputComponent";
import DropZone from "../../../components/DropZone/DropZone";
import { SimpleSearchParams } from "../../../hooks/SearchParams";
import { Api, DeleteApi } from "../../../services/Api";
import { ProductType } from "../../../types/Products";
import CopyIcon from "../../../assets/icons/copy.svg";
import { AxiosError, AxiosResponse } from "axios";
import { IsAdministrator } from "../../../services/Utils";
interface creationType {
  description: string;
  name: string;
  manufacturingValue: number;
  acquisitionValue: number;
  standardAmount: number;
  originalManufacturingValue: number;
}
const EditProduct = ({ product_default }: { product_default: ProductType }) => {
  let [loading, setLoading] = useState(false);
  let [product, setProduct] = useState<creationType>({
    description: "",
    acquisitionValue: 0,
    manufacturingValue: 0,
    name: "",
    standardAmount: 0,
    originalManufacturingValue: 0,
  });
  let [deleteProduct, setDeleteProduct] = useState("");
  let [ImgFile, setImgFile] = useState<File>();
  let [edited, setEdited] = useState<boolean>(false);
  let [errorMessage, setErrorMessage] = useState<string>();
  let SearchParams = SimpleSearchParams();
  const isAdmin = localStorage.getItem("isAdmin") === "true";

  const handleChange = (
    element: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setEdited(true);
    let target = element.currentTarget;
    setProduct({ ...product, [target.id]: target.value });
  };
  useEffect(() => {
    let data = product_default;
    setProduct({
      acquisitionValue: data.acquisitionValue,
      standardAmount: data.standardAmount,
      name: data.name,
      description: data.description,
      manufacturingValue: data.manufacturingValue,
      originalManufacturingValue: data.originalManufacturingValue || 0,
    });
  }, []);
  const handleDelete = async () => {
    if (deleteProduct !== "CONFIRMO")
      return setErrorMessage("A Confirmação deve ser realizada");

    let response = await DeleteApi(`/products/${product_default.productId}`)
      .then((res) => res)
      .catch((err) => {
        console.error(err);
        setErrorMessage("Erro ao deletar o produto");
        return err;
      });

    if (response.status === 204) {
      SearchParams.delete("modal");
    } else {
      setErrorMessage("Erro ao deletar o produto");
    }
  };
  const handleSubmit = async () => {
    if (
      !product.acquisitionValue ||
      !product.manufacturingValue ||
      !product.name ||
      !product.standardAmount ||
      !product.description
    )
      return setErrorMessage("Todos os campos são obrigatórios");

    let formData = new FormData();

    if (ImgFile) formData.append("file", ImgFile);

    formData.append("name", product.name);
    formData.append(
      "acquisitionValue",
      Number(product.acquisitionValue) * 100 + ""
    );
    formData.append(
      "manufacturingValue",
      Number(product.manufacturingValue) * 100 + ""
    );
    formData.append(
      "standardAmount",
      Number(product.standardAmount) * 100 + ""
    );
    formData.append(
      "originalManufacturingValue",
      Number(product.originalManufacturingValue) * 100 + ""
    );
    formData.append("description", product.description);
    setLoading(true);
    let response: AxiosResponse<ProductType> = await Api.put<
      AxiosResponse<ProductType>,
      AxiosError
    >("/products/" + product_default.productId, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => res)
      .catch((err) => {
        setErrorMessage("Ocorreu um erro na edição do produto");
        console.error(err);
        return err;
      });
    setLoading(false);
    if (response.status === 200) {
      SearchParams.delete("modal");
    } else {
      setErrorMessage("Ocorreu um erro na criação do produto");
    }
  };

  const handleChangeImg = (file: File) => {
    setEdited(true);
    setImgFile(file);
  };

  const copyDescription = () => {
    const tempElement = document.createElement("textarea");
    tempElement.value = product.description;
    document.body.appendChild(tempElement);
    tempElement.select();
    document.execCommand("copy");
    document.body.removeChild(tempElement);
  };

  return (
    <main id="updateProduct">
      <HeaderComponent
        title={IsAdministrator() ? "Editar Produto" : "Produto"}
      />
      <section style={isAdmin ? {} : { gridTemplateColumns: "100%" }}>
        <div>
          {errorMessage && <h2 className="error">{errorMessage}</h2>}
          <InputComponent
            id="name"
            label="Nome:"
            type="text"
            onChange={handleChange}
            value={product.name}
            required
            error={!product.name}
            disabled={!isAdmin}
          />
          {isAdmin ? (
            <div className="manufacturing">
              <InputComponent
                id="originalManufacturingValue"
                label="Custo de Produção:"
                type="number"
                step="0.01"
                min="0"
                onChange={handleChange}
                value={product.originalManufacturingValue}
              />
              <InputComponent
                id="manufacturingValue"
                label="Valor de venda fábrica:"
                type="number"
                step="0.01"
                min="0.01"
                onChange={handleChange}
                value={product.manufacturingValue}
                error={!product.manufacturingValue}
              />
            </div>
          ) : null}
          {isAdmin ? (
            <div className="values">
              <InputComponent
                id="acquisitionValue"
                label="Valor de aquisição:"
                type="number"
                step="0.01"
                min="0.01"
                onChange={handleChange}
                required
                value={product.acquisitionValue}
                error={!product.acquisitionValue}
              />
              {/* <span>
              <p>Comissão: </p>
              <p>
                R$
                {(
                  Number(product.acquisitionValue) -
                  Number(product.manufacturingValue)
                ).toFixed(2)}
              </p>
            </span> */}
              <span>
                <p>Lucro da fabrica: </p>
                <p>
                  R$
                  {(
                    Number(product.manufacturingValue) -
                    Number(product.originalManufacturingValue)
                  ).toFixed(2)}
                </p>
              </span>
            </div>
          ) : null}
          <div
            className="values"
            style={isAdmin ? {} : { gridTemplateColumns: "100%" }}
          >
            <InputComponent
              id="standardAmount"
              label="Valor sugerido:"
              type="number"
              step="0.01"
              min="0.01"
              value={product.standardAmount}
              onChange={handleChange}
              required
              error={!product.standardAmount}
              disabled={!isAdmin}
            />
            {isAdmin ? (
              <span>
                <p style={{ marginLeft: "1rem" }}>Lucro da loja: </p>
                <p>
                  R$
                  {(
                    Number(product.standardAmount) -
                    Number(product.acquisitionValue)
                  ).toFixed(2)}
                </p>
              </span>
            ) : null}
          </div>
          <div id="descricaoProduto" style={{ margin: "4px" }}>
            <label
              htmlFor="description"
              style={{ display: "flex", gap: "4px" }}
            >
              Descrição{" "}
              <img
                style={{ cursor: "pointer" }}
                src={CopyIcon}
                width={14}
                alt="logo"
                onClick={copyDescription}
              />
            </label>
            <textarea
              style={{
                // ...(product?.description?{}:{border: "1px solid #e94b4b"}),
                width: "100%",
                borderRadius: "8px",
                backgroundColor: "#EDEDEF",
                marginTop: "4px",
                padding: "8px",
              }}
              onChange={handleChange}
              value={product.description}
              name="description"
              id="description"
              rows={5}
              disabled={!isAdmin}
            ></textarea>
          </div>
        </div>
        {isAdmin ? (
          <div id="dropzone-container">
            <DropZone
              message="Selecione a imagem do produto"
              onChange={handleChangeImg}
              accept="image/png,image/jpg,image/jfif,image/jpeg"
              imagePreview
            />
            <div id="deleteProduct">
              <div>
                <InputComponent
                  id="deleteProduct"
                  label="Deletar o Produto:"
                  value={deleteProduct}
                  placeholder="CONFIRMO"
                  autoComplete="off"
                  onChange={(e) =>
                    setDeleteProduct(e.target.value.toLocaleUpperCase())
                  }
                />
              </div>
              <button
                onClick={handleDelete}
                disabled={deleteProduct !== "CONFIRMO"}
              >
                Deletar
              </button>
            </div>
          </div>
        ) : null}
      </section>
      {isAdmin ? (
        <footer>
          <button
            className="second"
            onClick={() => SearchParams.delete("modal")}
          >
            Cancelar
          </button>
          <button
            disabled={
              !edited ||
              !product.acquisitionValue ||
              !product.manufacturingValue ||
              !product.name ||
              !product.standardAmount ||
              loading
            }
            onClick={handleSubmit}
          >
            {loading ? "Carregando" : "Editar"}
          </button>
        </footer>
      ) : null}
    </main>
  );
};

export default EditProduct;
