import React from "react";
import Separator from "../Separator/Separator";
import "./styles.css";
import { SimpleSearchParams } from "../../hooks/SearchParams";
interface HeaderComponentTypes {
  title: string;
  search?: string | boolean;
  lead?: string;
}

const HeaderComponent = ({ title, search, lead }: HeaderComponentTypes) => {
  let SearchParams = SimpleSearchParams();
  return (
    <header id="HeaderComponent">
      <section id="content">
        <div>
          <h1>{title}</h1>
          {lead && <h3> {lead}</h3>}
        </div>
        <div>
          {search && (
            <input
              type="search"
              value={SearchParams.get("q") || ""}
              onChange={(e) => {
                if (e.target.value === "") {
                  SearchParams.delete("q");
                } else {
                  SearchParams.set("q", e.target.value);
                }
              }}
              placeholder={search !== true ? search : "Buscar"}
              style={{ backgroundColor: "#fff" }}
            />
          )}
        </div>
      </section>
      <Separator />
    </header>
  );
};

export default HeaderComponent;
